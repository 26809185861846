import _ from 'lodash';
export class OrdemServicoMovimentarSituacaoModel {
  constructor({
    id,
    codigo,
    situacao,
    ordensServicosInstrumentos = []
  }) {
    this.idOrdemServico = id;
    this.id = id;
    this.ordensServicosInstrumentos = ordensServicosInstrumentos.map(item => {
      return { ...item, situacaoNova: item.situacao }
    });
    this.codigo = codigo;
    this.situacao = situacao;
    this.situacaoNova = situacao;
  }
  get request() {
    let retorno = _.cloneDeep(this);
    retorno.ordensServicosInstrumentos = this.ordensServicosInstrumentos
      .map(item => {
        return { idOrdemServicoInstrumento: item.id, situacao: item.situacaoNova }
      }
      );
    return retorno;
  }
}
