<!-- eslint-disable -->
<template>
  <div>
    <cabecalho-pagina :titulo="$t('modulos.ordem_servico.titulos.os_movimentar_situacao')" sem-filtro sem-botoes
      sem-registros />
    <v-chip class="mt-1 mb-4 mr-1" small>
      {{ $t('modulos.ordem_servico.os_movimentar_situacao.ordem_servico') }} : {{ `${form.codigo}.2` }}
    </v-chip>
    <div class="row">
      <input-select v-model="form.situacao" :options="opcoes.EnumSituacaoOrdemServico"
        :label="$t('modulos.ordem_servico.os_movimentar_situacao.tabela.situacao_atual')"
        :placeholder="$t('geral.inputs.selecione')" class="form-control col-6" disabled />
      <input-select v-model="situacaoNova" :options="opcoes.EnumSituacaoOrdemServico" value="situacaoNova"
        :label="$t('modulos.ordem_servico.os_movimentar_situacao.tabela.situacao_nova')"
        :placeholder="$t('geral.inputs.selecione')" class="col-6" disabled />
    </div>
    <v-divider class="my-4" />
    <tabela-padrao-prime-vue :colunas="tabela.colunas" :mostrarSeletor="false" :dados="form.ordensServicosInstrumentos"
      :mostrar-acoes="false" sem-paginacao>

      <template v-slot:situacao="{ slotProps }">
        <div class="d-flex align-center justify-center">
          <input-select v-model="slotProps.data.situacao" :options="opcoes.EnumSituacaoOrdemServico"
            :placeholder="$t('geral.inputs.selecione')" class="col" disabled />
        </div>
      </template>

      <template v-slot:situacaoNova="{ slotProps }">
        <div class="d-flex align-center justify-center">
          <input-select v-model="slotProps.data.situacaoNova"
            :options="EnumSituacaoOrdemServicoPermitidas(slotProps.data)" :placeholder="$t('geral.inputs.selecione')"
            class="col" />
        </div>
      </template>

    </tabela-padrao-prime-vue>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon class="mr-1">
          $mdiCancel
        </v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar" :disabled="!podeSalvar()">
        <v-icon class="mr-1">
          $mdiContentSaveOutline
        </v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>

<script>
import CabecalhoPagina from '@/components/layout/CabecalhoPagina.vue';
import OrdemServicoService from '@/common/services/cadastros/OrdemServicoService.js';
import { OrdemServicoMovimentarSituacaoModel } from '@/common/models/cadastros/OrdemServicoMovimentarSituacaoModel';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import helpers from '@/common/utils/helpers';

export default {
  name: 'OrdemServicoMovimentarSituacao',
  components: {
    CabecalhoPagina,
  },
  props: ['id'],
  data() {
    return {
      form: new OrdemServicoMovimentarSituacaoModel({}),
      situacaoNova: null,
      tabela: {
        colunas: [
          {
            value: 'codigoTag',
            text: this.$t('modulos.ordem_servico.os_movimentar_situacao.tabela.instrumento'),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.ordem_servico.os_movimentar_situacao.tabela.tipo_instrumento'
            ),
            sortable: false,
          },
          {
            value: 'situacao',
            text: this.$t('modulos.ordem_servico.os_movimentar_situacao.tabela.situacao_atual'),
            sortable: false,
          },
          {
            value: 'situacaoNova',
            text: this.$t('modulos.ordem_servico.os_movimentar_situacao.tabela.situacao_nova'),
            sortable: false,
          },
          {
            value: 'setor.nome',
            text: this.$t('modulos.ordem_servico.os_movimentar_situacao.tabela.setor'),
            sortable: false,
          },
        ]
      },
      opcoes: {
        EnumSituacaoOrdemServico: [],
        EnumTipoAprovacao: [],
      },
    };
  },
  watch: {
    'form.ordensServicosInstrumentos': {
      handler() {
        this.situacaoNova = this.getSituacaoNova();
      },
      deep: true,
      immediate: true,
    },
    
  },
  async mounted() {
    helpers.redirecionarSemPermissao(this, 'OrdemServico', 'Editar')
    const promisesOpcoes = [];
    promisesOpcoes.push(this.buscarOrdemServico(this.id ?? this.$route.query.id ?? this.$route.params.id));
    promisesOpcoes.push(this.buscarSituacaoOrdemServicoEnum());
    promisesOpcoes.push(this.buscarTipoAprovacaoEnum());
    promisesOpcoes.push(this.alterarTitulo());
    await Promise.all(promisesOpcoes);
  },

  methods: {
    EnumSituacaoOrdemServicoPermitidas(ordemServicoInstrumento) {
      var enumSituacaoAtual = this.findEnumSituacaoOrdemServico(ordemServicoInstrumento.situacao);
      var permitidas = [enumSituacaoAtual];
      var emExecucaoManutencao = this.findEnumSituacaoOrdemServico('EmExecucaoManutencao');
      var aguardandoEnvioOrcamento = this.findEnumSituacaoOrdemServico('AguardandoEnvioOrcamento');
      var aguardandoAnaliseTecnica = this.findEnumSituacaoOrdemServico('AguardandoAnaliseTecnica');
      if (this.canMoveToAguardandoAnaliseTecnica(enumSituacaoAtual, aguardandoAnaliseTecnica))
        permitidas.push(aguardandoAnaliseTecnica);
      if (this.canMoveToAguardandoEnvioOrcamento(ordemServicoInstrumento, enumSituacaoAtual, aguardandoEnvioOrcamento))
        permitidas.push(aguardandoEnvioOrcamento);
      if (this.canMoveToemExecucaoManutencao(enumSituacaoAtual, emExecucaoManutencao))
        permitidas.push(emExecucaoManutencao);
      return permitidas;


    },
    getSituacaoNova() {
      if (!this.form.ordensServicosInstrumentos?.length)
        return this.form.situacao;
      var menorStatus = this.form.ordensServicosInstrumentos.reduce((menor, atual) => {
        return this.findEnumSituacaoOrdemServico(atual.situacaoNova)?.seq
          <= this.findEnumSituacaoOrdemServico(menor.situacaoNova)?.seq ? atual : menor;
      }, this.form.ordensServicosInstrumentos[0]);
      return menorStatus.situacaoNova ?? this.form.situacao;
    },
    canMoveToAguardandoEnvioOrcamento(ordemServicoInstrumento, enumSituacaoAtual, aguardandoEnvioOrcamento) {
      return enumSituacaoAtual.seq > aguardandoEnvioOrcamento.seq &&
        ordemServicoInstrumento.situacao != 'NaoCalibrado' &&
        (ordemServicoInstrumento.tipoAprovacao == 'Orcamento' || ordemServicoInstrumento.tipoAprovacao == 'Automatica');
    },
    canMoveToemExecucaoManutencao(enumSituacaoAtual, emExecucaoManutencao) {
      return enumSituacaoAtual.seq > emExecucaoManutencao.seq;
    },
    canMoveToAguardandoAnaliseTecnica(enumSituacaoAtual, aguardandoAnaliseTecnica) {
      return enumSituacaoAtual.seq > aguardandoAnaliseTecnica.seq;
    },
    findEnumSituacaoOrdemServico(situacao) {
      return this.opcoes.EnumSituacaoOrdemServico.find(f => f.value === situacao);
    },
    findEnumTipoAprovacao(situacao) {
      return this.opcoes.EnumTipoAprovacao.find(f => f.value === situacao);
    },
    podeSalvar() {
      return this.form.ordensServicosInstrumentos.some(s => s.situacaoNova != s.situacao);
    },
    labelSituacao(situacao) {
      return this.findEnumSituacaoOrdemServico(situacao)?.text;
    },
    atualizarSituacaoOrdemServico() {
      if (!this.form.ordensServicosInstrumentos.length)
        return;
      var menorStatus = this.form.ordensServicosInstrumentos.reduce((menor, atual) => {
        return this.findEnumSituacaoOrdemServico(atual.situacaoNova).seq <= this.findEnumSituacaoOrdemServico(menor.situacaoNova).seq ? atual : menor;
      }, this.form.ordensServicosInstrumentos[0]);
      this.form.situacaoNova = menorStatus.situacaoNova;
    },
    buscarOrdemServico: async function (id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await OrdemServicoService.buscar(id)
        .then((res) => {
          this.form.ordensServicosInstrumentos = res.data.ordensServicosInstrumentos;
          this.form = new OrdemServicoMovimentarSituacaoModel(res.data);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    alterarTitulo: async function () {
      this.$store.dispatch(
        'Layout/alterarTituloPagina',
        this.$t('modulos.ordem_servico.titulos.os_movimentar_situacao')
      );
    },
    buscarSituacaoOrdemServicoEnum: async function () {
      await EnumeradorService.buscar('EnumSituacaoOrdemServico').then(
        (res) => {
          this.opcoes.EnumSituacaoOrdemServico = res;
        }
      );
    },
    buscarTipoAprovacaoEnum: async function () {
      await EnumeradorService.buscar('EnumTipoAprovacaoAnaliseTecnica').then(
        (res) => {
          this.opcoes.EnumTipoAprovacao = res;
        }
      );
    },
    confirmacaoSalvar() {

      if (((this.id ?? this.$route.query.id ?? this.$route.params.id) ) && this.podeSalvar()) {
        this.confirmar(
          this.$t('geral.titulos.atencao'),
          this.$t('modulos.ordem_servico.os_movimentar_situacao.confirmar_movimentar'))
          .then(() => {
            this.salvar();
          });
      }
    },
    salvar: function () {
      this.form.ordemServicoInstrumentos = this.form.ordensServicosInstrumentos;
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrdemServicoService.reverterStatusOrdemServico(this.form.request)
        .then(() => {
          this.toastSucesso(
            this.$t('modulos.ordem_servico.os_movimentar_situacao.revert_sucesso')
          );

          this.$router.push({
            name: this.$route.query?.retorno || 'ordem-servico',
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({
          name: this.$route.query?.retorno || 'ordem-servico',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
